import NextErrorPage, { ErrorProps } from "next/error";
import React from "react";

export default function Error({ withDarkMode = false, ...props }: ErrorProps) {
    return <NextErrorPage {...props} withDarkMode={withDarkMode} />;
}

Error.getInitialProps = ({ res, err }: any) => {
    // eslint-disable-next-line no-nested-ternary
    const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
    return { statusCode };
};
